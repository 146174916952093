@import '../../Theme.scss';

.news-card-master {
	border-radius: 1em;
	overflow: hidden;
	box-shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.22);
	transition: 0.2s;
	height: 100%;
	background-color: $color-primario;

	&:hover {
		transform: scale(103%);
		box-shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.289);
	}

	.news-card-container {
		display: flex;
		font-family: $fuente-primaria;
		text-decoration: none !important;
		flex-direction: column;

		.card-img{
			height: 350px;
			object-fit: cover;
		}

		.card-news-text {
			display: flex;
			width: 100%;
			flex-direction: column;
			justify-content: flex-end;
			padding: 1rem;

			height: 100%;

			.solid {
				color: white;
				margin: 0.5em 0 1em;
			}

			.card-title {
				font-size: 1.1em;
				color: white;
				font-weight: bold;

				@media (max-width: 768px) {
					font-size: 0.9em;
					text-align: center;
				}
			}

			.card-subtitle {
				font-size: 0.9em;
				color: white;
				font-style: italic;

				@media (max-width: 768px) {
					font-size: 0.75em;
					text-align: center;
				}
			}
		}

		.card-news-button {
			@include boton-primario;
		}
	}
}
