@import '../../Theme.scss';

.leg-firebase-container {
	display: flex;
	width: 100vw;
	flex-direction: column;
	height: fit-content;
	align-items: center;
	justify-content: space-around;
	background-color: $color-secundario;
	padding: 1em 1em;
}

.leg-landing-container {
	display: flex;
	padding: 2em;
	height: 65vh;
	width: 100vw;
	background-color: white;
	justify-content: space-around;

	.home-text {
		font-family: $fuente-primaria;
		font-size: 1rem;
		padding-bottom: 1em;

		@media (max-width: 1024px) {
			font-size: 0.75em;
		}
	}

	@media (max-width: 768px) {
		height: 50vh;
		margin: 3em 0 3em;
	}

	.leg-text-container {
		display: flex;
		width: 40%;
		flex-direction: column;
		justify-content: center;

		@media (max-width: 768px) {
			width: 90%;
			align-items: center;
		}

		.leg-title {
			font-family: $fuente-primaria;
			font-size: 2em;
			font-weight: bolder;
			margin-bottom: 0.5em;

			@media (max-width: 1024px) {
				font-size: 1.2em;
				text-align: center;
				margin-bottom: 1em;
			}

			@media (max-width: 768px) {
				font-size: 1.1em;
			}
		}

		

		.home-dots {
			width: 30%;
			margin-bottom: 0.5em;
		}

		.leg-text-container {
			display: flex;
			font-family: $fuente-primaria;
			width: 100%;
			font-size: 0.9em;

			@media (max-width: 768px) {
				text-align: center;
				font-size: 0.75em;
			}

			.tooltip-word {
				background-color: $color-secundario;
				font-weight: bold;
				padding: 0 0.2em;
			}
		}
	}
	.leg-img-container {
		display: flex;
		width: 25em;
		align-items: center;
		justify-content: flex-end;

		@media (max-width: 768px) {
			display: none;
		}
	}
}
