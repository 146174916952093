@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;410;900&display=swap");
@media (max-width: 720px) {
  .nav-master {
    position: fixed;
    z-index: 1000;
    top: 0;
    justify-content: center;
  }
}
.nav-master .navbar-container {
  background-color: #eb164d;
  width: 100vw;
}
.nav-master .navbar-container .burger {
  color: white;
}
.nav-master .navbar-container .navbar-logo {
  font-family: "Raleway", sans-serif;
  color: white;
  text-decoration: none;
  font-weight: bolder;
  font-size: 0.9em;
}
@media (max-width: 720px) {
  .nav-master .navbar-container .navbar-logo {
    font-size: 0.6em;
  }
}
.nav-master .navbar-container .navbar-logo:hover {
  color: white;
}
.nav-master .navbar-container .navbar-links {
  font-family: "Raleway", sans-serif;
  font-size: 0.9em;
}
@media (max-width: 720px) {
  .nav-master .navbar-container .navbar-links {
    font-size: 0.7em;
  }
}
.nav-master .navbar-container .navbar-link-button {
  color: white !important;
  margin-left: 2em;
  font-weight: bold;
  text-decoration: none;
  padding: 0.2em;
  position: relative;
  letter-spacing: 0.4px;
}
@media (max-width: 720px) {
  .nav-master .navbar-container .navbar-link-button {
    margin-bottom: 1em;
  }
}
.nav-master .navbar-container .navbar-link-button:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #febe39;
  transition: width 0.2s ease-out;
}
.nav-master .navbar-container .navbar-link-button:hover:before {
  width: 100%;
}

.news-card-master {
  border-radius: 1em;
  overflow: hidden;
  box-shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.22);
  transition: 0.2s;
  height: 100%;
  background-color: #eb164d;
}
.news-card-master:hover {
  transform: scale(103%);
  box-shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.289);
}
.news-card-master .news-card-container {
  display: flex;
  font-family: "Raleway", sans-serif;
  text-decoration: none !important;
  flex-direction: column;
}
.news-card-master .news-card-container .card-img {
  height: 350px;
  object-fit: cover;
}
.news-card-master .news-card-container .card-news-text {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1rem;
  height: 100%;
}
.news-card-master .news-card-container .card-news-text .solid {
  color: white;
  margin: 0.5em 0 1em;
}
.news-card-master .news-card-container .card-news-text .card-title {
  font-size: 1.1em;
  color: white;
  font-weight: bold;
}
@media (max-width: 768px) {
  .news-card-master .news-card-container .card-news-text .card-title {
    font-size: 0.9em;
    text-align: center;
  }
}
.news-card-master .news-card-container .card-news-text .card-subtitle {
  font-size: 0.9em;
  color: white;
  font-style: italic;
}
@media (max-width: 768px) {
  .news-card-master .news-card-container .card-news-text .card-subtitle {
    font-size: 0.75em;
    text-align: center;
  }
}
.news-card-master .news-card-container .card-news-button {
  background-color: #eb164d !important;
  font-size: 0.9em;
  font-family: "Raleway", sans-serif;
  border: none !important;
  transition: 0.2s;
  align-items: center;
  justify-content: center;
}
.news-card-master .news-card-container .card-news-button:hover {
  background-color: #b8143d !important;
}
.news-card-master .news-card-container .card-news-button:active {
  outline: none !important;
  box-shadow: none !important;
}
.news-card-master .news-card-container .card-news-button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.news-list-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  max-width: 1280;
}
@media (max-width: 980px) {
  .news-list-container {
    padding-block: 1em;
    grid-template-columns: 1fr;
    max-width: 90vw;
  }
}

.news-container {
  display: flex;
  width: 100dvw;
  flex-direction: column;
  height: fit-content;
  align-items: center;
  justify-content: space-around;
  background-color: #85aad8;
  padding: 2em 2em;
  overflow-x: hidden;
}
@media (max-width: 768px) {
  .news-container {
    padding: 0.5em 0.5em;
  }
}
.news-container .news-container-button {
  background-color: #eb164d !important;
  font-size: 0.9em;
  font-family: "Raleway", sans-serif;
  border: none !important;
  transition: 0.2s;
  align-items: center;
  justify-content: center;
  margin: 1em 1em;
}
.news-container .news-container-button:hover {
  background-color: #b8143d !important;
}
.news-container .news-container-button:active {
  outline: none !important;
  box-shadow: none !important;
}
.news-container .news-container-button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.spinner {
  margin: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-container {
  display: flex;
  padding: 2em;
  height: 65vh;
  width: 100vw;
  justify-content: center;
}
@media (max-width: 768px) {
  .home-container {
    height: 50vh;
    margin: 3em 0 3em;
  }
}
.home-container .home-text-container {
  display: flex;
  width: 40%;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 768px) {
  .home-container .home-text-container {
    align-items: center;
    text-align: center;
    width: 90%;
  }
}
.home-container .home-text-container .home-title {
  font-family: "Raleway", sans-serif;
  font-size: 2em;
  font-weight: bolder;
  padding-bottom: 0.5em;
}
@media (max-width: 1024px) {
  .home-container .home-text-container .home-title {
    font-size: 1.1em;
  }
}
.home-container .home-text-container .home-text {
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  padding-bottom: 1em;
}
@media (max-width: 1024px) {
  .home-container .home-text-container .home-text {
    font-size: 0.75em;
  }
}
.home-container .home-text-container .home-dots {
  width: 30%;
}
@media (max-width: 768px) {
  .home-container .home-text-container .home-dots {
    margin-bottom: -3em;
  }
}
.home-container .home-img-container {
  display: flex;
  width: 40em;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 1024px) {
  .home-container .home-img-container {
    width: 30em;
  }
}
@media (max-width: 768px) {
  .home-container .home-img-container {
    display: none;
  }
}
.home-container .second-img-container {
  display: flex;
  width: 25%;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .home-container .second-img-container {
    display: none;
  }
}

.footer-container {
  display: flex;
  width: 100vw;
  background-color: #eb164d;
  font-family: "Raleway", sans-serif;
  justify-content: center;
  align-items: center;
  padding: 2em 2em;
}
@media (max-width: 720px) {
  .footer-container {
    flex-direction: column-reverse;
  }
}
.footer-container .footer-logo-container {
  display: flex;
  padding: 2em;
  justify-content: center;
  margin-left: 2em;
  margin-right: 2em;
}
.footer-container .footer-logo-container .footer-logo {
  display: flex;
  width: 15em;
}
@media (max-width: 1024px) {
  .footer-container .footer-logo-container .footer-logo {
    width: 10em;
  }
}
.footer-container .footer-datos-contaier {
  display: flex;
  width: 20%;
  color: white;
  margin-top: 1em;
  font-size: 0.8em;
  flex-direction: column;
  align-items: flex-end;
}
@media (max-width: 720px) {
  .footer-container .footer-datos-contaier {
    width: 80%;
    font-size: 0.7em;
  }
}
.footer-container .footer-datos-contaier ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
  text-align: right;
}
@media (max-width: 720px) {
  .footer-container .footer-datos-contaier ul {
    text-align: center;
  }
}
.footer-container .footer-datos-contaier li {
  margin-bottom: 0.5em;
}
.footer-container .footer-datos-contaier span {
  font-weight: bold;
}
.footer-container .footer-datos-contaier .rrss-icons-container {
  display: flex;
  justify-content: right;
  width: 100%;
}
@media (max-width: 720px) {
  .footer-container .footer-datos-contaier .rrss-icons-container {
    justify-content: center;
  }
}
.footer-container .footer-datos-contaier .rrss-icons-container a {
  text-decoration: none;
}
@media (max-width: 720px) {
  .footer-container .footer-datos-contaier .rrss-icons-container a {
    margin: 0;
  }
}
.footer-container .footer-datos-contaier .rrss-icons-container .rrss-icons {
  display: flex;
  margin-left: 1em;
  font-size: 1.2em;
  transition: 0.2s;
  color: white;
}
@media (max-width: 720px) {
  .footer-container .footer-datos-contaier .rrss-icons-container .rrss-icons {
    text-align: center;
    margin: 1em 1em 1em 1em;
    font-size: 1.5em;
  }
}
.footer-container .footer-datos-contaier .rrss-icons-container .rrss-icons:hover {
  color: #febe39;
}
.footer-container .footer-form-container {
  display: flex;
  width: 30%;
  justify-content: left;
}
@media (max-width: 720px) {
  .footer-container .footer-form-container {
    width: 100%;
    justify-content: center;
  }
}
.footer-container .footer-form-container .form-container {
  display: flex;
  width: 75%;
  flex-direction: column;
  padding: 1.5em 0 1.5em;
}
@media (max-width: 720px) {
  .footer-container .footer-form-container .form-container {
    width: 90%;
  }
}
.footer-container .footer-form-container .input-group {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.footer-container .footer-form-container .input-group .form-input {
  width: 49%;
  margin-bottom: 0.3em;
  font-size: 0.7em;
  border: none;
  padding: 0.3em;
  border-radius: 0;
}
@media (max-width: 720px) {
  .footer-container .footer-form-container .input-group .form-input {
    font-size: 0.75em;
  }
}
.footer-container .footer-form-container .form-title {
  color: white;
  font-size: 1em;
}
.footer-container .footer-form-container .form-textarea {
  margin-top: 0.3em;
  font-size: 0.7em;
  border: none;
  padding: 0.3em;
  border-radius: 0;
}
@media (max-width: 720px) {
  .footer-container .footer-form-container .form-textarea {
    font-size: 0.75em;
  }
}
.footer-container .footer-form-container .form-submit {
  font-size: 0.7em;
  border: none;
  width: fit-content;
  padding: 0.3em;
  margin-top: 0.3em;
  transition: 0.2s;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  background-color: rgb(231, 231, 231);
  border-radius: 0;
  color: black;
}
@media (max-width: 720px) {
  .footer-container .footer-form-container .form-submit {
    font-size: 0.75em;
    margin-top: 0.5em;
  }
}
.footer-container .footer-form-container .form-submit:hover {
  background-color: #febe39;
}

.news-detail-container {
  display: flex;
  width: 100vw;
  padding: 2em;
}

.news-detail {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 2em;
}
@media (max-width: 768px) {
  .news-detail {
    flex-direction: column;
    margin-top: 4em;
    align-items: center;
  }
}
.news-detail .news-detail-text {
  width: 50%;
}
.news-detail .news-detail-text p {
  margin-bottom: 0 !important;
}
@media (max-width: 768px) {
  .news-detail .news-detail-text {
    width: 90%;
    align-items: center;
    text-align: center;
  }
}
.news-detail .news-detail-text h1 {
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: 1em;
  background-color: #febe39;
  width: fit-content;
  padding: 0.5em;
}
@media (max-width: 768px) {
  .news-detail .news-detail-text h1 {
    font-size: 1.3em;
    text-align: center;
    width: 100%;
  }
}
.news-detail .news-detail-text h3 {
  font-size: 0.8em;
  color: grey;
  font-style: italic;
  margin-bottom: 1em;
}
.news-detail .news-detail-text img {
  width: 13em;
  margin-bottom: 1em;
}
@media (max-width: 768px) {
  .news-detail .news-detail-text img {
    display: none;
  }
}
.news-detail .news-detail-text .news-detail-button {
  background-color: #eb164d !important;
  font-size: 0.9em;
  font-family: "Raleway", sans-serif;
  border: none !important;
  transition: 0.2s;
  align-items: center;
  justify-content: center;
}
.news-detail .news-detail-text .news-detail-button:hover {
  background-color: #b8143d !important;
}
.news-detail .news-detail-text .news-detail-button:active {
  outline: none !important;
  box-shadow: none !important;
}
.news-detail .news-detail-text .news-detail-button:focus {
  outline: none !important;
  box-shadow: none !important;
}
.news-detail .news-img-container {
  width: 30%;
}
@media (max-width: 768px) {
  .news-detail .news-img-container {
    margin-top: 2em;
    width: 90%;
  }
}
.news-detail .news-img-container img {
  width: 100%;
}

.leg-firebase-container {
  display: flex;
  width: 100vw;
  flex-direction: column;
  height: fit-content;
  align-items: center;
  justify-content: space-around;
  background-color: #febe39;
  padding: 1em 1em;
}

.leg-landing-container {
  display: flex;
  padding: 2em;
  height: 65vh;
  width: 100vw;
  background-color: white;
  justify-content: space-around;
}
.leg-landing-container .home-text {
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  padding-bottom: 1em;
}
@media (max-width: 1024px) {
  .leg-landing-container .home-text {
    font-size: 0.75em;
  }
}
@media (max-width: 768px) {
  .leg-landing-container {
    height: 50vh;
    margin: 3em 0 3em;
  }
}
.leg-landing-container .leg-text-container {
  display: flex;
  width: 40%;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 768px) {
  .leg-landing-container .leg-text-container {
    width: 90%;
    align-items: center;
  }
}
.leg-landing-container .leg-text-container .leg-title {
  font-family: "Raleway", sans-serif;
  font-size: 2em;
  font-weight: bolder;
  margin-bottom: 0.5em;
}
@media (max-width: 1024px) {
  .leg-landing-container .leg-text-container .leg-title {
    font-size: 1.2em;
    text-align: center;
    margin-bottom: 1em;
  }
}
@media (max-width: 768px) {
  .leg-landing-container .leg-text-container .leg-title {
    font-size: 1.1em;
  }
}
.leg-landing-container .leg-text-container .home-dots {
  width: 30%;
  margin-bottom: 0.5em;
}
.leg-landing-container .leg-text-container .leg-text-container {
  display: flex;
  font-family: "Raleway", sans-serif;
  width: 100%;
  font-size: 0.9em;
}
@media (max-width: 768px) {
  .leg-landing-container .leg-text-container .leg-text-container {
    text-align: center;
    font-size: 0.75em;
  }
}
.leg-landing-container .leg-text-container .leg-text-container .tooltip-word {
  background-color: #febe39;
  font-weight: bold;
  padding: 0 0.2em;
}
.leg-landing-container .leg-img-container {
  display: flex;
  width: 25em;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 768px) {
  .leg-landing-container .leg-img-container {
    display: none;
  }
}

.digesto-list-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 90%;
  flex-direction: column;
  padding-block: 1em;
}
.digesto-list-container .leg-input {
  display: flex;
  padding: 1em;
  font-size: 0.9em;
  font-family: "Raleway", sans-serif;
  border-radius: 1.5em;
  color: black;
  margin-bottom: 1em;
  border: 1px solid #febe39;
  transition: 0.2s;
}
@media (max-width: 768px) {
  .digesto-list-container .leg-input {
    width: 90%;
    font-size: 0.75em;
  }
}
.digesto-list-container .leg-input:focus {
  outline: 2px solid #85aad8;
}
.digesto-list-container .table {
  font-size: small;
  font-family: "Raleway", sans-serif;
}
.digesto-list-container .table thead {
  text-transform: uppercase;
}
.digesto-list-container .table tr {
  border-color: black;
}
.digesto-list-container .table td {
  background-color: rgba(255, 255, 255, 0.364);
}
.digesto-list-container .table .table-title {
  max-width: 70ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}
.digesto-list-container .table .centered {
  text-align: center;
}

@media (max-width: 768px) {
  .dig-card-master {
    display: flex;
    width: 90%;
    justify-content: center;
  }
}
.dig-card-master .dig-card-container {
  box-shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.22);
  display: flex;
  margin: 1.5em;
  width: 15vw;
  min-height: fit-content;
  font-family: "Raleway", sans-serif;
  border: none !important;
  border-radius: 1em !important;
}
@media (max-width: 1366px) {
  .dig-card-master .dig-card-container {
    width: 25vw;
  }
}
@media (max-width: 768px) {
  .dig-card-master .dig-card-container {
    min-width: 100%;
  }
}
.dig-card-master .dig-card-container .dig-card-number {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #eb164d;
  border-radius: 1em 1em 0 0 !important;
  color: white;
  font-size: 0.9em;
}
.dig-card-master .dig-card-container .dig-card-text {
  font-size: 0.8em;
  font-weight: bolder;
}
.dig-card-master .dig-card-container .dig-card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dig-card-master .dig-card-container .dig-card-tema {
  font-size: 0.8em;
  background-color: #febe39;
  width: fit-content;
  font-style: italic;
  padding: 0.1em;
}
.dig-card-master .dig-card-container .dig-card-download {
  background-color: #eb164d !important;
  font-size: 0.9em;
  font-family: "Raleway", sans-serif;
  border: none !important;
  transition: 0.2s;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
  border-radius: 0 0 1em 1em;
}
.dig-card-master .dig-card-container .dig-card-download:hover {
  background-color: #b8143d !important;
}
.dig-card-master .dig-card-container .dig-card-download:active {
  outline: none !important;
  box-shadow: none !important;
}
.dig-card-master .dig-card-container .dig-card-download:focus {
  outline: none !important;
  box-shadow: none !important;
}
.dig-card-master .dig-card-container .row-2 {
  align-items: center;
}
.dig-card-master .dig-card-container .row-2 .dig-card-date {
  font-size: 0.8em;
  color: grey;
  font-style: italic;
  margin-bottom: 0;
  margin-right: 1em;
}
.dig-card-master .dig-card-container .row-2 .dig-card-vigente {
  font-size: 0.7em;
  border-radius: 1em;
  color: green;
  border: 1px solid green;
  padding: 0.2em 0.5em 0.2em 0.4em;
  font-style: italic;
  height: fit-content;
  text-align: center;
}
.dig-card-master .dig-card-container .row-2 .dig-card-vigente-false {
  font-size: 0.7em;
  border: 1px solid red;
  color: red;
  padding: 0.2em 0.5em 0.2em 0.4em;
  border-radius: 1em;
  font-style: italic;
  height: fit-content;
  text-align: center;
}

.leg-button {
  background-color: #eb164d !important;
  font-size: 0.9em;
  font-family: "Raleway", sans-serif;
  border: none !important;
  transition: 0.2s;
  align-items: center;
  justify-content: center;
  width: fit-content;
}
.leg-button:hover {
  background-color: #b8143d !important;
}
.leg-button:active {
  outline: none !important;
  box-shadow: none !important;
}
.leg-button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.leg-button-2 {
  background-color: #eb164d !important;
  font-size: 0.9em;
  font-family: "Raleway", sans-serif;
  border: none !important;
  transition: 0.2s;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin-top: 1.5em;
}
.leg-button-2:hover {
  background-color: #b8143d !important;
}
.leg-button-2:active {
  outline: none !important;
  box-shadow: none !important;
}
.leg-button-2:focus {
  outline: none !important;
  box-shadow: none !important;
}

.timeline-container {
  background-color: #85aad8;
  width: 100vw;
  padding: 0 2em;
  font-family: "Raleway", sans-serif;
}
.timeline-container .vertical-timeline-element-content {
  box-shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.22);
}
.timeline-container .vertical-timeline-element-content p {
  font-size: 0.8em;
}
.timeline-container .vertical-timeline-element-title {
  font-weight: bolder;
  font-size: 1.2em;
  background-color: #febe39;
  width: fit-content;
  padding: 0 0.2em;
}
.timeline-container .vertical-timeline-element-subtitle {
  text-decoration: underline;
  font-size: 1em;
  background-color: #febe39;
  width: fit-content;
  padding: 0 0.2em;
}
.timeline-container .vertical-timeline-element-date {
  font-weight: bolder !important;
  opacity: 1 !important;
  color: white;
}
@media (max-width: 1170px) {
  .timeline-container .vertical-timeline-element-date {
    background-color: #eb164d;
    padding: 0.5em !important;
    margin-top: 0.5em;
    justify-content: center;
    font-weight: 400 !important;
  }
}

.home-container {
  display: flex;
  padding: 2em;
  align-items: center;
  height: 65dvh;
  width: 100vw;
}
.home-container .home-text-container {
  display: flex;
  width: 40%;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2em;
}
@media (max-width: 768px) {
  .home-container .home-text-container {
    align-items: center;
    text-align: center;
    width: 90%;
  }
}
.home-container .home-text-container .home-title {
  font-family: "Raleway", sans-serif;
  font-size: 2em;
  font-weight: bolder;
  padding-bottom: 0.5em;
}
@media (max-width: 1024px) {
  .home-container .home-text-container .home-title {
    font-size: 1.1em;
  }
}
.home-container .inputs-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 40%;
}
.home-container .inputs-container input {
  padding-block: 5px;
  padding-inline-start: 10px;
  border-radius: 10px;
}
.home-container .inputs-container .inputs-btn {
  background-color: #eb164d;
  border: none;
}
.home-container .inputs-container .error p {
  color: red;
}

.admin-container {
  width: 100vw;
  display: flex;
  padding: 2em;
  gap: 10px;
}
.admin-container .lista-noticias {
  background-color: rgb(215, 215, 215);
  padding-inline: 0.5em;
  padding-block: 0.2em;
  margin-top: 0.5em;
}
.admin-container .wrapper {
  width: 50%;
  padding: 2em;
  background-color: rgb(231, 231, 231);
  height: fit-content;
}
.admin-container .wrapper h2 {
  font-family: "Raleway", sans-serif;
}
.admin-container .wrapper h4 {
  margin-top: 20px;
}
.admin-container .wrapper .form-wrapper {
  display: flex;
  flex-direction: column;
}
.admin-container .wrapper .form-wrapper input,
.admin-container .wrapper .form-wrapper textarea {
  margin-bottom: 20px;
  padding-block: 5px;
  padding-inline: 10px;
}
.admin-container .wrapper .form-wrapper .checkbox {
  align-self: baseline;
  margin-top: 5px;
}
.admin-container .wrapper .form-wrapper .error p {
  margin-top: 10px;
  color: red;
}
.admin-container .wrapper .form-wrapper .success p {
  margin-top: 10px;
  color: green;
}
.admin-container .wrapper .form-wrapper .ql-container.ql-snow {
  background-color: white !important;
  min-height: 200px;
  margin-bottom: 20px;
}
.admin-container .wrapper ul {
  margin-top: 20px;
}
.admin-container .wrapper ul .list-item {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(175, 175, 175);
}
.admin-container .wrapper ul .list-item li {
  max-width: 60ch; /* Ajusta este valor al ancho que prefieras */
  white-space: nowrap; /* Asegura que el texto no baje de línea */
  overflow: hidden; /* Oculta cualquier contenido que pase del contenedor */
  text-overflow: ellipsis;
}
.admin-container .wrapper ul .list-item button {
  background-color: darkred;
  color: white;
}

body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  scroll-behavior: smooth;
}

