.news-detail {
	display: flex;
	width: 100%;
	justify-content: space-around;
	margin-bottom: 2em;

	@media (max-width: 768px) {
		flex-direction: column;
		margin-top: 4em;
		align-items: center;
	}

	.news-detail-text {
		width: 50%;

		p{
			margin-bottom: 0 !important;
		}

		@media (max-width: 768px) {
			width: 90%;
			align-items: center;
			text-align: center;
		}

		h1 {
			font-weight: bold;
			font-size: 1.5em;
			margin-bottom: 1em;
			background-color: $color-secundario;
			width: fit-content;
			padding: 0.5em;

			@media (max-width: 768px) {
				font-size: 1.3em;
				text-align: center;
				width: 100%;
			}
		}

		h3 {
			font-size: 0.8em;
			color: grey;
			font-style: italic;
			margin-bottom: 1em;
		}

		img {
			width: 13em;
			margin-bottom: 1em;

			@media (max-width: 768px) {
				display: none;
			}
		}

		.news-detail-button {
			@include boton-primario;
		}
	}

	.news-img-container {
		width: 30%;

		@media (max-width: 768px) {
			margin-top: 2em;
			width: 90%;
		}

		img {
			width: 100%;
		}
	}
}
