@import '../../Theme.scss';

.news-container {
	display: flex;
	width: 100dvw;
	flex-direction: column;
	height: fit-content;
	align-items: center;
	justify-content: space-around;
	background-color: $color-terciario;
	padding: 2em 2em;
	overflow-x: hidden;

	@media (max-width: 768px){
		padding: 0.5em 0.5em;
	}

	.news-container-button {
		@include boton-primario;
		margin: 1em 1em;
	}
}
