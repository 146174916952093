//Colores
$color-primario: #eb164d;
$color-secundario: #febe39;
$color-terciario: #85aad8;

//Fuentes
$fuente-primaria: 'Raleway', sans-serif;

//Mixins
@mixin boton-primario {
	background-color: #eb164d !important;
	font-size: 0.9em;
	font-family: 'Raleway', sans-serif;
	border: none !important;
	transition: 0.2s;
	align-items: center;
	justify-content: center;

	&:hover {
		background-color: #b8143d !important;
	}

	&:active {
		outline: none !important;
		box-shadow: none !important;
	}

	&:focus {
		outline: none !important;
		box-shadow: none !important;
	}
}
