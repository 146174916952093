@import '../../Theme.scss';

.home-container {
	display: flex;
	padding: 2em;
	height: 65vh;
	width: 100vw;
	justify-content: center;

	@media (max-width: 768px) {
		height: 50vh;
		margin: 3em 0 3em;
	}

	.home-text-container {
		display: flex;
		width: 40%;
		flex-direction: column;
		justify-content: center;

		@media (max-width: 768px) {
			align-items: center;
			text-align: center;
			width: 90%;
		}

		.home-title {
			font-family: $fuente-primaria;
			font-size: 2em;
			font-weight: bolder;
			padding-bottom: 0.5em;

			@media (max-width: 1024px) {
				font-size: 1.1em;
			}
		}

		.home-text {
			font-family: $fuente-primaria;
			font-size: 1rem;
			padding-bottom: 1em;

			@media (max-width: 1024px) {
				font-size: 0.75em;
			}
		}
		.home-dots {
			width: 30%;

			@media (max-width: 768px) {
				margin-bottom: -3em;
			}
		}
	}

	.home-img-container {
		display: flex;
		width: 40em;
		align-items: center;
		justify-content: flex-end;

		@media (max-width: 1024px) {
			width: 30em;
		}

		@media (max-width: 768px) {
			display: none;
		}
	}

	.second-img-container {
		display: flex;
		width: 25%;
		align-items: center;
		justify-content: center;

		@media (max-width: 768px) {
			display: none;
		}
	}
}
