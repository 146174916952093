@import './components/NavBar/NavBar.scss';
@import './components/News/News.scss';
@import './components/NewsList/NewsList.scss';
@import './components/NewsContainer/NewsContainer.scss';
@import './components/Loading/Loading.scss';
@import './components/LandingHome/LandingHome.scss';
@import './components/Footer/Footer.scss';
@import './components/NewsDetailContainer/NewsDetailContainer.scss';
@import './components/NewsDetail/NewsDetail.scss';
@import './components/DigestoContainer/DigestoContainer.scss';
@import './components/DigestoList/DigestoList.scss';
@import './components/Digestos/Digestos.scss';
@import './components/LandingSecond/LandingSecond.scss';
@import './components/Timeline/Timeline.scss';
@import './components/Login/LoginForm.scss';
@import './components/AdminDashboard/AdminDashboard.scss';

body {
	margin: 0;
	font-family: 'Raleway', sans-serif;
	overflow-x: hidden;
	justify-content: center;
	align-items: center;
	scroll-behavior: smooth;
}
