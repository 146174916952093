@import '../../Theme.scss';

.footer-container {
	display: flex;
	width: 100vw;
	background-color: $color-primario;
	font-family: $fuente-primaria;
	justify-content: center;
	align-items: center;
	padding: 2em 2em;

	@media (max-width: 720px) {
		flex-direction: column-reverse;
	}

	.footer-logo-container {
		display: flex;
		padding: 2em;
		justify-content: center;
		margin-left: 2em;
		margin-right: 2em;

		.footer-logo {
			display: flex;
			width: 15em;

			@media (max-width: 1024px) {
				width: 10em;
			}
		}
	}

	.footer-datos-contaier {
		display: flex;
		width: 20%;
		color: white;
		margin-top: 1em;
		font-size: 0.8em;
		flex-direction: column;
		align-items: flex-end;

		@media (max-width: 720px) {
			width: 80%;
			font-size: 0.7em;
		}

		ul {
			list-style-type: none;
			padding: 0;
			margin-bottom: 0;
			text-align: right;

			@media (max-width: 720px) {
				text-align: center;
			}
		}

		li {
			margin-bottom: 0.5em;
		}

		span {
			font-weight: bold;
		}

		.rrss-icons-container {
			display: flex;
			justify-content: right;
			width: 100%;

			@media (max-width: 720px) {
				justify-content: center;
			}

			a {
				text-decoration: none;

				@media (max-width: 720px) {
					margin: 0;
				}
			}

			.rrss-icons {
				display: flex;
				margin-left: 1em;
				font-size: 1.2em;
				transition: 0.2s;
				color: white;

				@media (max-width: 720px) {
					text-align: center;
					margin: 1em 1em 1em 1em;
					font-size: 1.5em;
				}

				&:hover {
					color: $color-secundario;
				}
			}
		}
	}

	.footer-form-container {
		display: flex;
		width: 30%;
		justify-content: left;

		@media (max-width: 720px) {
			width: 100%;
			justify-content: center;
		}

		.form-container {
			display: flex;
			width: 75%;
			flex-direction: column;
			padding: 1.5em 0 1.5em;

			@media (max-width: 720px) {
				width: 90%;
			}
		}

		.input-group {
			display: flex;
			width: 100%;
			justify-content: space-between;

			.form-input {
				width: 49%;
				margin-bottom: 0.3em;
				font-size: 0.7em;
				border: none;
				padding: 0.3em;
				border-radius: 0;

				@media (max-width: 720px) {
					font-size: 0.75em;
				}
			}
		}

		.form-title {
			color: white;
			font-size: 1em;
		}

		.form-textarea {
			margin-top: 0.3em;
			font-size: 0.7em;
			border: none;
			padding: 0.3em;
			border-radius: 0;

			@media (max-width: 720px) {
				font-size: 0.75em;
			}
		}

		.form-submit {
			font-size: 0.7em;
			border: none;
			width: fit-content;
			padding: 0.3em;
			margin-top: 0.3em;
			transition: 0.2s;
			font-family: 'Raleway', sans-serif;
			font-weight: 400;
			background-color: rgb(231, 231, 231);
			border-radius: 0;
			color: black;

			@media (max-width: 720px) {
				font-size: 0.75em;
				margin-top: 0.5em;
			}

			&:hover {
				background-color: $color-secundario;
			}
		}
	}
}
