.timeline-container {
	background-color: $color-terciario;
	width: 100vw;
	padding: 0 2em;
	font-family: $fuente-primaria;

	.vertical-timeline-element-content {
		box-shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.22);

		p {
			font-size: 0.8em;
		}
	}

	.vertical-timeline-element-title {
		font-weight: bolder;
		font-size: 1.2em;
		background-color: $color-secundario;
		width: fit-content;
		padding: 0 0.2em;
	}

	.vertical-timeline-element-subtitle {
		text-decoration: underline;
		font-size: 1em;
		background-color: $color-secundario;
		width: fit-content;
		padding: 0 0.2em;
	}

	.vertical-timeline-element-date {
		font-weight: bolder !important;
		opacity: 1 !important;
		color: white;

		@media (max-width: 1170px) {
			background-color: $color-primario;
			padding: 0.5em !important;
			margin-top: 0.5em;
			justify-content: center;
            font-weight: 400 !important;
		}
	}
}
