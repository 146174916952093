.news-list-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 3rem;
	max-width: 1280;

	@media (max-width: 980px) {
		padding-block: 1em;
		grid-template-columns: 1fr;
		max-width: 90vw;
	}
}
